import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'
import styles from './page-header.module.css'

function PageHeader({ children }) {
  return (
    <StaticQuery
      query={graphql`
        {
          homeImage: file(name: { eq: "home" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={(data) => (
        <div className={styles.pageHeader}>
          <div className="container h-100">
            <div className={styles.pageHeaderText}>{children}</div>
          </div>
          <div className="overlaybg">
            <Img fluid={data.homeImage.childImageSharp.fluid} />
          </div>
        </div>
      )}
    />
  )
}
export default PageHeader
